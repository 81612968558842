import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { TelemedicineImagesQuery } from '../shared/interfaces';

const TelemedicinePage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<TelemedicineImagesQuery>(graphql`
        query {
            telemedicineBackgroundImage: file(name: {eq: "telemedicineBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            telemedicineSummaryImage: file(name: {eq: "telemedicineSummary"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Telemedicine"/>
            <GeneralJumbotron
                title="Telemedicine"
                backgroundImageURL={queryResult?.telemedicineBackgroundImage.publicURL}
                backgroundText="Telemedicine"
            />
            <SummaryTemplate image={queryResult?.telemedicineSummaryImage.publicURL}>
                <p>
                    {`
                        At PCH IPA, we offer our very own patented telemedicine software.
                        You can now leverage a state-of-the-art platform with security
                        and technology infrastructure that supports one of the largest
                        payers in the industry. Let’s reduce administration costs and
                        standardize the use of electronic transactions together!
                    `}
                </p>
            </SummaryTemplate>
            <CallToAction/>
        </MainLayout>
    );
};

export default TelemedicinePage;
